export const GUIDES_INDEX = {
  en: {
    seo: {
      title: 'Guides and e-books',
      description:
        'Take a deep dive into the Screver guides. Get expert knowledge on Customer Experience (CX) and Employee Experience (EX).',
      image: '',
    },
    hero: {
      title: 'Guides and e-books',
      subtitle:
        'Take a deep dive into the Screver guides. Get expert knowledge on Customer Experience (CX) and Employee Experience (EX).',
    },
    langSwitcher: 'Guide language',
  },
  de: {
    seo: {
      title: 'Leitfäden und E-Books',
      description:
        'Tauchen Sie tief in die Screver-Handbücher ein. Holen Sie sich Expertenwissen zu Customer Experience (CX) und Employee Experience (EX).',
      image: '',
    },
    hero: {
      title: 'Leitfäden und E-Books',
      subtitle:
        'Tauchen Sie tief in die Screver-Handbücher ein. Holen Sie sich Expertenwissen zu Customer Experience (CX) und Employee Experience (EX).',
    },
    langSwitcher: 'Sprache des Leitfadens',
  },
};
